import { cva, VariantProps } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

const grid = cva(['grid'], {
  variants: {
    cols: {
      3: ['grid-cols-3', 'gap-x-9', 'gap-y-9'],
      4: ['grid-cols-4', 'gap-x-6', 'gap-y-6'],
    },
  },
  defaultVariants: {
    cols: 3,
  },
});

export type CardsGridProps = PropsWithChildren & VariantProps<typeof grid>;

export const CardsGrid = ({ cols, children }: CardsGridProps) => {
  return <div className={grid({ cols })}>{children}</div>;
};

import useTranslation from 'next-translate/useTranslation';
import { getVideoLink, getVideoPoster, Video } from '@afishauz/api/videos';
import { Box } from '@afishauz/ui-kit/box';
import { Container } from '@afishauz/ui-kit/container';
import { SectionTitle } from '@afishauz/core/widgets/section-title';
import { Carousel, CarouselItem } from '@afishauz/ui-kit/desktop-carousel';
import { Card } from '@afishauz/core/widgets/card';
import { VideoRatingBadge } from '@afishauz/core/widgets/video-rating-badge';
import { CardMeta, CardTitle } from '@afishauz/ui-kit/card';
import { TicketBadge } from '@afishauz/core/widgets/ticket-badge';

interface Props {
  videos: Video[];
}

export const Cinema = ({ videos }: Props) => {
  const { t } = useTranslation('home');

  return (
    <Box component='section' py='xl'>
      <Container>
        <SectionTitle href='/cinema'>{t('cinema')}</SectionTitle>
        <Carousel
          slidesPerView={6}
          slidesPerGroup={5}
          gap={24}
          controlsOffsetY={94}>
          {videos.map(video => (
            <CarouselItem key={video['@id']}>
              <Card
                link={{ href: getVideoLink(video) }}
                img={{ src: getVideoPoster(video, 'small'), alt: video.title }}
                badges={[
                  {
                    component: (
                      <span className='flex gap-x-1'>
                        {video.showsTicketsAvailable && (
                          <TicketBadge
                            title={t('common:elements.tickets.long')}
                          />
                        )}
                        <VideoRatingBadge rating={Number(video.imdbRating)} />
                      </span>
                    ),
                  },
                ]}
                orientation='portrait'>
                <CardTitle title={video.title}>{video.title}</CardTitle>
                <CardMeta>{video.genres[0]?.name}</CardMeta>
              </Card>
            </CarouselItem>
          ))}
        </Carousel>
      </Container>
    </Box>
  );
};

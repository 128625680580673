import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faPercent } from '@fortawesome/free-solid-svg-icons/faPercent';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import { parseLocale } from '@afishauz/core/config';
import { SOCIAL_MEDIA } from '@afishauz/core/config';
import { Box } from '@afishauz/ui-kit/box';
import { Container } from '@afishauz/ui-kit/container';
import { SectionTitle } from '@afishauz/core/widgets/section-title';
import { Link } from '@afishauz/core/widgets/link';

export const SeeAlso = () => {
  const { lang } = useTranslation('home');
  const locale = parseLocale(lang);

  return (
    <Box component='section' py='xl'>
      <Container>
        <SectionTitle />
        <nav className='flex gap-x-6'>
          <Link
            href='/calendar'
            className='relative w-72 h-31 p-6 rounded-md bg-blue-600 hover-backdrop'>
            <article>
              <h3 className='text-lg text-white'>
                <Trans
                  i18nKey='also.calendar'
                  ns='home'
                  components={{ 1: <br /> }}
                />
              </h3>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className='absolute top-6 right-6 text-2xl text-white'
              />
            </article>
          </Link>
          <Link
            href='/restaurants'
            className='relative w-72 h-31 p-6 rounded-md bg-yellow-600 hover-backdrop'>
            <article>
              <h3 className='text-lg text-white'>
                <Trans
                  i18nKey='also.restaurants'
                  ns='home'
                  components={{ 1: <br /> }}
                />
              </h3>
              <FontAwesomeIcon
                icon={faUtensils}
                className='absolute top-6 right-6 text-2xl text-white'
              />
            </article>
          </Link>
          <Link
            href='/shops'
            className='relative w-72 h-31 p-6 rounded-md bg-[url("/assets/images/actual-item-percent.png")] bg-cover bg-center bg-no-repeat hover-backdrop'>
            <article>
              <h3 className='text-lg text-white'>
                <Trans
                  i18nKey='also.discount'
                  ns='home'
                  components={{ 1: <br /> }}
                />
              </h3>
              <FontAwesomeIcon
                icon={faPercent}
                className='absolute top-6 right-6 text-2xl text-white'
              />
            </article>
          </Link>
          <a
            href={SOCIAL_MEDIA[locale].TELEGRAM}
            target='_blank'
            rel='noreferrer'
            className='relative w-72 h-31 p-6 rounded-md bg-sky-500 hover-backdrop'>
            <article>
              <h3 className='text-lg text-white'>
                <Trans
                  i18nKey='also.telegram'
                  ns='home'
                  components={{ 1: <br /> }}
                />
              </h3>
              <FontAwesomeIcon
                icon={faTelegramPlane}
                className='absolute top-6 right-6 text-2xl text-white'
              />
            </article>
          </a>
        </nav>
      </Container>
    </Box>
  );
};

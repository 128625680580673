import useTranslation from 'next-translate/useTranslation';
import { getMaterialMainImage, Material } from '@afishauz/api/materials';
import { SectionTitle } from '@afishauz/core/widgets/section-title';
import { ListItemTitle } from '@afishauz/ui-kit/list';
import { ListItem } from '@afishauz/core/widgets/list-item';
import { CardMeta } from '@afishauz/ui-kit/card';
import { ListItemsGrid } from '@afishauz/core/widgets/list-items-grid';
import { VideoBadge } from '@afishauz/core/widgets/video-badge';

interface Props {
  highlights: Material[];
}

export const Highlights = ({ highlights }: Props) => {
  const { t } = useTranslation('home');

  if (highlights.length === 0) {
    return null;
  }

  return (
    <section>
      <SectionTitle>{t('highlights')}</SectionTitle>
      <ListItemsGrid>
        {highlights.map(material => (
          <ListItem
            link={{
              href: material.permalinkCanonical,
              native: material.tildaSubstitute,
            }}
            img={{
              src: getMaterialMainImage(material, 'small'),
              alt: material.title,
              size: 'lg',
            }}
            badges={[
              {
                component: material.type === 'VIDEO' && (
                  <VideoBadge size='sm' />
                ),
              },
            ]}
            key={material['@id']}>
            <ListItemTitle title={material.title}>
              {material.title}
            </ListItemTitle>
            <CardMeta>
              {material.sections.map(section => section.name).join(', ')}
            </CardMeta>
          </ListItem>
        ))}
      </ListItemsGrid>
    </section>
  );
};

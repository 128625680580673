import useTranslation from 'next-translate/useTranslation';
import { getMaterialMainImage, Material } from '@afishauz/api/materials';
import { Box } from '@afishauz/ui-kit/box';
import { SectionTitle } from '@afishauz/core/widgets/section-title';
import { ListItemsGrid } from '@afishauz/core/widgets/list-items-grid';
import { ListItem } from '@afishauz/core/widgets/list-item';
import { PhotoBadge } from '@afishauz/core/widgets/photo-badge';
import { ListItemMeta, ListItemTitle } from '@afishauz/ui-kit/list';
import { PublishedAt } from '@afishauz/core/widgets/published-at';

interface Props {
  photosets: Material[];
}

export const Photosets = ({ photosets }: Props) => {
  const { t } = useTranslation('home');

  return (
    <div className='w-[808px] min-w-[808px]'>
      <Box component='section' py='xl'>
        <SectionTitle href='/photo'>{t('photos')}</SectionTitle>
        <ListItemsGrid>
          {photosets.map(material => (
            <ListItem
              link={{
                href: material.permalinkCanonical,
                native: material.tildaSubstitute,
              }}
              img={{
                src: getMaterialMainImage(material, 'small'),
                alt: material.title,
                size: 'lg',
              }}
              badges={[
                {
                  component: (
                    <PhotoBadge photosCount={material.photosetImagesCount} />
                  ),
                },
              ]}
              key={material['@id']}>
              <ListItemTitle title={material.title}>
                {material.title}
              </ListItemTitle>
              <ListItemMeta>
                <PublishedAt date={new Date(material.publishedAt)} />
              </ListItemMeta>
            </ListItem>
          ))}
        </ListItemsGrid>
      </Box>
    </div>
  );
};

import { GetStaticPropsContext } from 'next';
import { Translate } from 'next-translate';
import { add } from 'date-fns';
import { API_ORIGIN, DEFAULT_PREVIEW, Locale } from '@afishauz/api/config';
import { getEventSchedules } from '@afishauz/api/event-schedules';
import { getMaterials, Material } from '@afishauz/api/materials';
import { getVideosSchedule } from '@afishauz/api/videos';
import { getPerformancesSchedule } from '@afishauz/api/performances';
import { getFeedItems, FeedItem } from '@afishauz/api/feed-items';
import {
  getNormalizedISODateString,
  getOptimizedDateString,
  getTashkentISODateString,
} from '@afishauz/lib/date';
import { parseLocale } from '../config';
import { CreateGetStaticProps } from './config';
import { emptyCollection } from '@afishauz/api/collection';

export function createGetStaticProps({
  isMobile,
  revalidate = 20,
}: CreateGetStaticProps) {
  return async (ctx: GetStaticPropsContext) => {
    const locale = parseLocale(ctx.locale);

    const eventSchedulesPromise = getHomePageEvents({ locale });
    const expectedEventsPromise = isMobile
      ? emptyCollection<Material>()
      : getHomePageExpectedEvents({ locale });
    const newsAndReviewsPromise = getHomePageNewsAndReviews({ locale });
    const highlightsPromise = getHomePageHighlights({ locale });
    const videosPromise = getHomePageVideos({ locale });
    const performancesPromise = getHomePagePerformances({ locale });
    const photosetsPromise = getHomePagePhotosets({ locale });
    const feedPromise = isMobile
      ? emptyCollection<FeedItem>()
      : getNewsFeed({ locale });

    const [
      eventSchedules,
      expected,
      newsAndReviews,
      highlights,
      videos,
      performances,
      photosets,
      feedItems,
    ] = await Promise.all([
      eventSchedulesPromise,
      expectedEventsPromise,
      newsAndReviewsPromise,
      highlightsPromise,
      videosPromise,
      performancesPromise,
      photosetsPromise,
      feedPromise,
    ]);

    return {
      props: {
        eventSchedules: eventSchedules['hydra:member'],
        expected: expected['hydra:member'],
        newsAndReviews,
        highlights: highlights['hydra:member'],
        videos: videos['hydra:member'],
        performances: performances['hydra:member'],
        photosets: photosets['hydra:member'],
        feedItems: feedItems['hydra:member'],
      },
      revalidate,
    };
  };
}

export async function getHomePageEvents({ locale }: { locale: Locale }) {
  return getEventSchedules({
    itemsPerPage: 9,
    isOnHomepage: 1,
    locale,
    'groups[]': ['material:permalink_canonical'],
  });
}

export async function getHomePageExpectedEvents({
  locale,
}: {
  locale: Locale;
}) {
  return getMaterials({
    locale,
    'eventSchedules.startedAt[after]': getTashkentISODateString(new Date()),
    'publishedAt[before]': getOptimizedDateString(),
    'order[eventSchedules.startedAt]': 'ASC',
    isOnHomepage: 1,
    'groups[]': [
      'material:main_media_object',
      'media_object:read',
      'media_object:variants',
      'material:event_schedules',
      'place:read',
      'material:permalink_canonical',
    ],
    itemsPerPage: 8,
    'priority[]': ['HIGH'],
    'type[]': ['EVENT'],
    'status[]': ['PUBLISHED'],
  });
}

export async function getHomePageNewsAndReviews({
  locale,
}: {
  locale: Locale;
}) {
  return getMaterials({
    locale,
    'order[publishedAt]': 'DESC',
    'publishedAt[before]': getOptimizedDateString(),
    isOnHomepage: 1,
    'groups[]': [
      'material:main_media_object',
      'media_object:read',
      'media_object:variants',
      'material:permalink_canonical',
    ],
    itemsPerPage: 15,
    'type[]': ['NEWS', 'ARTICLE', 'PHOTOSET', 'VIDEO'],
    'status[]': ['PUBLISHED'],
  });
}

export async function getHomePageHighlights({ locale }: { locale: Locale }) {
  return getMaterials({
    locale,
    'order[publishedAt]': 'DESC',
    'publishedAt[before]': getOptimizedDateString(),
    isOnHomepage: 1,
    'groups[]': [
      'material:main_media_object',
      'media_object:read',
      'media_object:variants',
      'material:sections',
      'section:read',
      'material:permalink_canonical',
    ],
    itemsPerPage: 8,
    'priority[]': ['HIGH'],
    'type[]': ['NEWS', 'ARTICLE', 'PHOTOSET', 'VIDEO'],
    'status[]': ['PUBLISHED'],
  });
}

export async function getHomePageVideos({ locale }: { locale: Locale }) {
  return getVideosSchedule({
    startedAtAfter: getNormalizedISODateString(new Date()),
    startedAtStrictlyBefore: getNormalizedISODateString(
      add(new Date(), { days: 7 }),
    ),
    type: 'MOVIE',
    itemsPerPage: 10,
    locale,
  });
}

export async function getHomePagePerformances({ locale }: { locale: Locale }) {
  return getPerformancesSchedule({
    startedAtAfter: getNormalizedISODateString(new Date()),
    startedAtStrictlyBefore: getNormalizedISODateString(
      add(new Date(), { days: 7 }),
    ),
    itemsPerPage: 6,
    locale,
  });
}

export async function getHomePagePhotosets({ locale }: { locale: Locale }) {
  return getMaterials({
    locale,
    'order[publishedAt]': 'DESC',
    'publishedAt[before]': getOptimizedDateString(),
    isOnHomepage: 1,
    'groups[]': [
      'material:main_media_object',
      'media_object:read',
      'media_object:variants',
      'material:permalink_canonical',
    ],
    itemsPerPage: 6,
    'type[]': ['PHOTOSET'],
    'status[]': ['PUBLISHED'],
  });
}

export async function getNewsFeed({ locale }: { locale: Locale }) {
  return getFeedItems({
    'source[]': ['gazeta.uz'],
    itemsPerPage: 4,
    locale,
  });
}

export const generateMetadata = (t: Translate) => {
  const title = t('meta.title');
  const description = t('meta.description');
  return {
    title,
    description,
    ogImage: new URL(DEFAULT_PREVIEW, API_ORIGIN).toString(),
    alternate: [
      { hrefLang: 'ru', href: 'https://www.afisha.uz/ru' },
      { hrefLang: 'uz-Latn', href: 'https://www.afisha.uz/uz' },
    ],
  };
};

import useTranslation from 'next-translate/useTranslation';
import { getMaterialMainImage, Material } from '@afishauz/api/materials';
import { Box } from '@afishauz/ui-kit/box';
import { Container } from '@afishauz/ui-kit/container';
import { SectionTitle } from '@afishauz/core/widgets/section-title';
import { Carousel, CarouselItem } from '@afishauz/ui-kit/desktop-carousel';
import { EventSchedule } from '@afishauz/core/widgets/event-schedule';
import { Card } from '@afishauz/core/widgets/card';
import { CardMeta, CardTitle } from '@afishauz/ui-kit/card';
import {
  DelimitedList,
  DelimitedListItem,
} from '@afishauz/ui-kit/delimited-list';

interface Props {
  expected: Material[];
}

export const Expected = ({ expected }: Props) => {
  const { t } = useTranslation('home');

  return (
    <Box component='section' py='xl'>
      <Container>
        <SectionTitle>{t('expected_events')}</SectionTitle>
        <Carousel
          slidesPerView={3}
          controlsOffsetY={95}
          gap={36}
          slidesPerGroup={2}>
          {expected.map(material => {
            const place = material.eventSchedules[0]?.place?.name;
            return (
              <CarouselItem key={material['@id']}>
                <Card
                  link={{
                    href: material.permalinkCanonical,
                    native: material.tildaSubstitute,
                  }}
                  img={{
                    src: getMaterialMainImage(material, 'medium'),
                    alt: material.title,
                  }}
                  size='lg'>
                  <CardTitle title={material.title}>{material.title}</CardTitle>
                  <CardMeta>
                    <DelimitedList>
                      <DelimitedListItem>
                        <EventSchedule schedules={material.eventSchedules} />
                      </DelimitedListItem>
                      {place && <DelimitedListItem>{place}</DelimitedListItem>}
                    </DelimitedList>
                  </CardMeta>
                </Card>
              </CarouselItem>
            );
          })}
        </Carousel>
      </Container>
    </Box>
  );
};

import { clsx } from 'clsx';

export interface VideoRatingBadgeProps {
  prefix?: string;
  rating: number;
}

const LOW = 5;
const HIGH = 7;

export const VideoRatingBadge = ({ rating, prefix }: VideoRatingBadgeProps) => {
  if (!rating) {
    return null;
  }

  return (
    <span
      className={clsx(
        'inline-block rounded p-1 text-xs text-white leading-none',
        rating < HIGH && 'bg-gray-400/50 backdrop-blur-[6px]',
        rating >= HIGH && 'bg-gradient-to-r from-[#1e8200] to-[#2ab900]',
      )}>
      {!!prefix && <>{prefix}&nbsp;</>}
      {Number(rating).toPrecision(2)}
    </span>
  );
};

import useTranslation from 'next-translate/useTranslation';
import { FeedItem } from '@afishauz/api/feed-items';
import { Box } from '@afishauz/ui-kit/box';
import { SectionTitle } from '@afishauz/core/widgets/section-title';
import { Link } from '@afishauz/core/widgets/link';
import { ListItemMeta } from '@afishauz/ui-kit/list';
import { PublishedAt } from '@afishauz/core/widgets/published-at';

interface Props {
  items: FeedItem[];
}

export const UzbekistanNews = ({ items }: Props) => {
  const { t } = useTranslation('home');

  return (
    <Box component='section' py='xl'>
      <SectionTitle href='https://gazeta.uz'>
        {t('uzbekistan_news')}
      </SectionTitle>
      <ul className='flex flex-col gap-y-4'>
        {items.map(item => (
          <li key={item['@id']}>
            <Link
              href={String(item.permalink)}
              target='_blank'
              rel='noreferrer'
              className='hover:text-afisha transition-colors'>
              <article>
                <h3 className='text-lg font-semibold'>{item.title}</h3>
                <ListItemMeta>
                  <PublishedAt date={new Date(item.createdAt)} />
                </ListItemMeta>
              </article>
            </Link>
          </li>
        ))}
      </ul>
    </Box>
  );
};

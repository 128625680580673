import useTranslation from 'next-translate/useTranslation';
import {
  getPerformanceLink,
  getPerformancePoster,
  Performance,
} from '@afishauz/api/performances';
import { Box } from '@afishauz/ui-kit/box';
import { Container } from '@afishauz/ui-kit/container';
import { SectionTitle } from '@afishauz/core/widgets/section-title';
import { ListItemsGrid } from '@afishauz/core/widgets/list-items-grid';
import { ListItem } from '@afishauz/core/widgets/list-item';
import { ListItemMeta, ListItemTitle } from '@afishauz/ui-kit/list';
import { Alert } from '@afishauz/ui-kit/alert';

interface Props {
  performances: Performance[];
}

export const Performances = ({ performances }: Props) => {
  const { t } = useTranslation('home');

  return (
    <Box component='section' py='xl'>
      <Container>
        <SectionTitle href='/theatres'>{t('theatres')}</SectionTitle>
        {!performances.length ? (
          <Alert type='info' size='large'>
            {t('no_performances')}
          </Alert>
        ) : (
          <ListItemsGrid cols={3}>
            {performances.map(performance => (
              <ListItem
                link={{ href: getPerformanceLink(performance) }}
                img={{
                  src: getPerformancePoster(performance, 'small'),
                  alt: performance.title,
                  size: 'lg',
                }}
                key={performance['@id']}>
                <ListItemTitle title={performance.title}>
                  {performance.title}
                </ListItemTitle>
                <ListItemMeta>{performance.theater?.name}</ListItemMeta>
              </ListItem>
            ))}
          </ListItemsGrid>
        )}
      </Container>
    </Box>
  );
};

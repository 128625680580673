import useTranslation from 'next-translate/useTranslation';
import { parseLocale, AD_ZONES } from '@afishauz/core/config';
import { Events } from '@/templates/home/events';
import { Highlights } from '@/templates/home/highlights';
import { Expected } from '@/templates/home/expected';
import { SeeAlso } from '@/templates/home/see-also';
import { NewsAndReviews } from '@/templates/shared/news-and-reviews';
import { Cinema } from '@/templates/home/cinema';
import { Performances } from '@/templates/home/performances';
import { Photosets } from '@/templates/home/photosets';
import { UzbekistanNews } from '@/templates/home/uzbekistan-news';
import { Container } from '@afishauz/ui-kit/container';
import { SectionLayout } from '@/layouts/section-layout';
import { HorizontalBanner } from '@/templates/shared/horizontal-banner';
import { Box } from '@afishauz/ui-kit/box';
import { Metadata } from '@afishauz/core/widgets/metadata';
import {
  createGetStaticProps,
  generateMetadata,
} from '@afishauz/core/pages/home';
import { InferGetStaticPropsType } from 'next';

const HomePage = ({
  eventSchedules,
  expected,
  newsAndReviews,
  highlights,
  videos,
  performances,
  photosets,
  feedItems,
}: InferGetStaticPropsType<typeof createGetStaticProps>) => {
  const { t, lang } = useTranslation('home');
  const locale = parseLocale(lang);

  return (
    <>
      <Metadata data={generateMetadata(t)} />
      <Events eventSchedules={eventSchedules} />
      <Box py='xl'>
        <SectionLayout>
          <Highlights highlights={highlights} />
        </SectionLayout>
      </Box>
      <Expected expected={expected} />
      <SeeAlso />
      <HorizontalBanner zoneId={AD_ZONES[locale].DESKTOP_CONTENT} />
      <NewsAndReviews isOnHomepage={true} initialData={newsAndReviews} />
      <Cinema videos={videos} />
      <Performances performances={performances} />
      <Container>
        <div className='flex gap-x-8'>
          <Photosets photosets={photosets} />
          <UzbekistanNews items={feedItems} />
        </div>
      </Container>
    </>
  );
};

export const getStaticProps = createGetStaticProps({ isMobile: false });

export default HomePage;

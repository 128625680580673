import useTranslation from 'next-translate/useTranslation';
import { EventSchedule as EventScheduleType } from '@afishauz/api/event-schedules';
import { getMaterialMainImage } from '@afishauz/api/materials';
import { Box } from '@afishauz/ui-kit/box';
import { Container } from '@afishauz/ui-kit/container';
import { SectionTitle } from '@afishauz/core/widgets/section-title';
import { ListItem } from '@afishauz/core/widgets/list-item';
import { ListItemTitle } from '@afishauz/ui-kit/list';
import { CardMeta } from '@afishauz/ui-kit/card';
import { EventSchedule } from '@afishauz/core/widgets/event-schedule';
import { ListItemsGrid } from '@afishauz/core/widgets/list-items-grid';

interface Props {
  eventSchedules: EventScheduleType[];
}

export const Events = ({ eventSchedules }: Props) => {
  const { t } = useTranslation('home');

  return (
    <Box component='section' pb='xl' pt='none'>
      <Container>
        <SectionTitle>{t('events')}</SectionTitle>
        <ListItemsGrid cols={3}>
          {eventSchedules.map(schedule => {
            const { event: material } = schedule;
            if (!material) {
              return null;
            }
            return (
              <ListItem
                link={{
                  href: material.permalinkCanonical,
                  native: material.tildaSubstitute,
                }}
                img={{
                  src: getMaterialMainImage(material, 'extra_small'),
                  alt: material.title,
                }}
                key={material['@id']}>
                <ListItemTitle title={material.title}>
                  {material.title}
                </ListItemTitle>
                <CardMeta>
                  <EventSchedule schedules={[schedule]} />
                </CardMeta>
              </ListItem>
            );
          })}
        </ListItemsGrid>
      </Container>
    </Box>
  );
};

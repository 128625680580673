import useTranslation from 'next-translate/useTranslation';

export interface PhotoBadgeProps {
  photosCount?: number;
}

export const PhotoBadge = ({ photosCount }: PhotoBadgeProps) => {
  const { t } = useTranslation();

  if (!photosCount) {
    return null;
  }

  return (
    <div className='relative inline-block'>
      <span className='p-1 bg-black/50 rounded text-[11px] flex justify-center items-center text-white backdrop-blur'>
        {photosCount}&nbsp;{t('elements.photo_badge.text')}
      </span>
    </div>
  );
};

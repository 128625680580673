import { GetCollectionOptions } from '../collection';
import {
  SWROptions,
  useCollection,
  UseCollectionOptions,
} from '../use-collection';
import {
  GetEventSchedulesDatesParams,
  GetEventSchedulesParams,
} from './service';
import { EventSchedule } from './types';

export function useEventSchedules(
  options: UseCollectionOptions<
    EventSchedule,
    Omit<GetEventSchedulesParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<EventSchedule>(
    'api/event_schedules/current_and_upcoming',
    options,
    requestOptions,
    swrConfig,
  );
}

export function useEventSchedulesDates(
  options: UseCollectionOptions<
    string,
    Omit<GetEventSchedulesDatesParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<string>(
    'api/event_schedules/dates',
    options,
    requestOptions,
    swrConfig,
  );
}
